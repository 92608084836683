/* global.css */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	font-family: 'Inter', sans-serif;
	background-color: #ffffff;
	color: #0e0f11;
	overflow: hidden; /* Отключаем прокрутку */
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh; /* Устанавливаем высоту на весь экран */
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 32px;
}

/* Кнопки */
.btn {
	display: inline-block;
	width: fit-content;
	padding: 15px 30px;
	background-color: #046cf4;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	text-decoration: none;
	border-radius: 30px;
	transition: background-color 0.1s linear;
}

.btn:hover {
	background-color: #0356c3;
}

/* Ссылки */
.link {
	text-decoration: none;
	font-weight: 400;
	color: #000;
	line-height: 24px;
	transition: color 0.1s linear;
}

.link:hover {
	color: #525252;
}

/* Адаптивность */

/* Планшеты */
@media (max-width: 1024px) {
	html,
	body {
		overflow: auto;
	}

	.container {
		padding: 24px;
	}

	.btn {
		padding: 10px 20px;
		font-size: 16px;
	}

	.link {
		font-size: 16px;
	}
}

/* Мобильные устройства (ширина 768px и меньше) */
@media (max-width: 768px) {
	.container {
		padding: 24px;
	}

	.btn {
		padding: 10px 20px;
		font-size: 16px;
	}

	.link {
		font-size: 16px;
	}

	.footer {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 16px;
	}

	.links {
		flex-direction: column;
		gap: 12px;
	}
}

/* Очень маленькие экраны (ширина до 480px) */
@media (max-width: 480px) {
	.container {
		padding: 16px;
	}

	.btn {
		padding: 8px 16px;
		font-size: 14px;
	}

	.link {
		font-size: 14px;
	}

	.footer {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.links {
		flex-direction: column;
		gap: 12px;
	}
}
