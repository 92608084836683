/* Home.module.css */
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.header h1 {
	font-size: 32px;
	letter-spacing: -2px;
	color: #0e0f11;
}

.logo-link {
	text-decoration: none;
	color: inherit;
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	flex: 1; /* Контент занимает все доступное пространство между шапкой и футером */
	max-width: 1600px;
	width: 100%;
}

.spline-scene {
	flex: 1;
	height: 100%; /* Устанавливаем сцену на всю доступную высоту */
}

.text-section {
	width: 600px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.text-section h1 {
	font-size: 64px;
	line-height: 64px;
	letter-spacing: -2px;
}

.text-section h2 {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	letter-spacing: -1px;
}

.text-section p {
	line-height: 24px;
}

.footer {
	border-top: 1px solid rgba(0, 0, 0, 0.22);
	padding-top: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.links {
	display: flex;
	gap: 24px;
}

.link {
	text-decoration: none;
	font-weight: 400;
	color: #000;
	line-height: 24px;
	transition: color 0.1s linear;
}

.link:hover {
	color: #525252;
}

/* Адаптивные стили для разных экранов */

/* Планшеты */
@media (max-width: 1024px) {
	.content {
		flex-direction: column;
		align-items: center;
		padding: 32px 0;
	}

	.spline-scene {
		height: 50vh; /* Регулируем высоту для планшетов */
	}

	.text-section {
		width: 100%;
	}

	.text-section h1 {
		font-size: 48px;
		margin-bottom: 40px;
	}

	.footer {
		border-top: 1px solid rgba(0, 0, 0, 0.22);
		padding-top: 24px;
		padding-bottom: 24px;
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

/* Ноутбуки */
@media (max-width: 1520px) {
	.content {
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
	}

	.spline-scene {
		height: 500px;
		width: 500px; /* Регулируем высоту для планшетов */
	}

	.text-section {
		width: 50%;
	}

	.text-section h1 {
		font-size: 48px;
	}

	.footer {
		border-top: 1px solid rgba(0, 0, 0, 0.22);
		padding-top: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

/* Мобильные устройства */
@media (max-width: 768px) {
	.content {
		display: flex;
		flex-direction: column;
		padding: 0;
		gap: 0;
	}

	.spline-scene {
		max-height: 30vh;
		width: 100%;
	}

	.text-section {
		width: 100%;
	}

	.header h1 {
		font-size: 24px;
		letter-spacing: -1px;
	}

	.text-section h1 {
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 24px;
		letter-spacing: -1px;
	}

	.text-section h2 {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -1px;
	}

	.footer {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 16px;
	}

	.links {
		flex-direction: column;
		gap: 12px;
	}
}

/* Очень маленькие экраны */
@media (max-width: 480px) {
	.spline-scene {
		height: 30vh; /* Регулируем высоту для очень маленьких экранов */
	}

	.text-section h1 {
		font-size: 28px;
		line-height: 32px;
		letter-spacing: -1px;
	}

	.text-section h2 {
		font-size: 18px;
		line-height: 22px;
		letter-spacing: normal;
	}

	.text-section p {
		line-height: 20px;
	}
}
