/* Privacy.module.css */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 32px;
  letter-spacing: -2px;
  color: #0e0f11;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #046cf4;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
  transition: background-color 0.1s linear;
}

.btn:hover {
  background-color: #0356c3;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex: 1;
  max-width: 1600px;
  width: 100%;
}

.spline-scene {
  flex: 1;
  height: 100%;
}

.text-section-wrapper {
  position: relative;
  width: 600px;
  max-height: 80vh;
}

.text-section {
  z-index: 1;
  overflow-y: auto;
  padding-right: 16px;
  max-height: 80vh;
  scrollbar-width: none;
}

.text-section::-webkit-scrollbar {
  display: none;
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(transparent, #ffffff);
  pointer-events: none;
  z-index: 2;
}

.text-section h1 {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -2px;
  margin-bottom: 80px;
}

.text-section h2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 24px;
}

.text-section p {
  margin-bottom: 32px;
  line-height: 24px;
}

.text-section a {
  text-decoration: none;
  font-weight: bold;
  color: #000;
  line-height: 24px;
  transition: color 0.1s linear;
}

.text-section a:hover {
  color: #525252;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.22);
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links {
  display: flex;
  gap: 24px;
}

.link {
  text-decoration: none;
  font-weight: 400;
  color: #000;
  line-height: 24px;
  transition: color 0.1s linear;
}

.link:hover {
  color: #525252;
}

/* Адаптивные стили для планшетов и мобильных устройств */

@media (max-width: 1024px) {
  .text-section-wrapper {
    width: 100%;
    max-height: none;
  }

  .text-section {
    overflow-y: visible;
    max-height: none;
  }

  .fade-overlay {
    display: none;
  }

  .spline-scene {
    height: 50vh;
  }

  .content {
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }

  .text-section h1 {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }

  .spline-scene {
    height: 40vh;
  }

  .header h1 {
    font-size: 24px;
    letter-spacing: -1px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 16px;
  }

  .text-section h1 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 24px;
    letter-spacing: -1px;
  }

  .text-section h2 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
  }

  .links {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 480px) {
  .btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .container {
    padding: 16px;
  }

  .spline-scene {
    height: 30vh;
  }

  .text-section p {
    line-height: 20px;
  }

  .text-section h1 {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1px;
  }

  .text-section h2 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: normal;
  }
}
